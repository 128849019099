@import "../../sass/base/breakpoints";

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.container.full {
  max-width: none;
  padding: 0;
}

.container.small {
  max-width: 800px;
}

.container.xsmall {
  max-width: 600px;
}

.container.middle {
  max-width: 1000px;
}

.container.large {
  max-width: 1400px;
}


$current: 5;

@while $current <= 150 {
  .u-margin-top-#{$current} {
    margin-top: $current + px;
  }

  .u-margin-bottom-#{$current} {
    margin-bottom: $current + px;
  }

  .padding-top-#{$current} {
    padding-top: $current + px;
  }

  .padding-bottom-#{$current} {
    padding-bottom: $current + px;
  }

  .padding-top-m-#{$current} {
    @include media-breakpoint('m') {
      padding-top: $current + px !important;
    }
  }

  .padding-bottom-m-#{$current} {
    @include media-breakpoint('m') {
      padding-bottom: $current + px !important;
    }
  }

  $current: $current + 5;
}

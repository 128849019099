@import '../../../../sass/base/breakpoints.scss';

.term {
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  cursor: pointer;

  @include media-breakpoint('m') {
    display: block;
    padding: 15px;
    border-bottom: 1px solid var(--color-blue--dark);
    margin: 0 15px;
  }
}

.term.selected {
  background: var(--color-blue--dark);
  color: var(--color-white);
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.input:focus ~ .term {
  outline: 3px solid var(--color-blue--dark);
  outline-offset: 2px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.like-h1,
.like-h2,
.like-h3,
.like-h4,
.like-h5,
.like-h6 {
  font-family: 'Montserrat', sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

h1,
.like-h1 {
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 50px;
}

.page-870 h1,
.page-870 .like-h1 {
  color: var( --color-pink );
}

h2,
.like-h2 {
  font-size: 1.625rem;
  line-height: 34px;
}

h3,
.like-h3 {
  font-size: 1.375rem;
  line-height: 30px;
}

h4,
.like-h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 26px;
}

h5,
.like-h5 {
  font-size: 1.125rem;
  line-height: 26px;
}

h6,
.like-h6 {
  font-size: 1rem;
  line-height: 24px;
}

@media only screen and ( max-width: 900px ) {
  h1,
  .like-h1 {
    font-size: 1.625rem;
    line-height: 29px;
  }
}

@import "../../../../sass/base/breakpoints";

.search {
  position: relative;
  display: flex;

  @include media-breakpoint('m') {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}

.label {
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: var(--color-pink);
  background-color: transparent;
  border: 3px solid var(--color-pink);
  padding: 10px 30px;
  order: -1;
  transition: all .1s;

  @include media-breakpoint('m') {
    display: block;
    width: 100%;
  }
}

.input {
  background: var(--color-pink);
  border: 3px solid var(--color-pink);
  padding: 10px 45px 10px 10px;
  font-size: 1.5rem;
  font-weight: 900;
  display: inline-block;
  color: var(--color-white);
  outline: none;
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;

  @include media-breakpoint('m') {
    display: block;
  }
}

.input:focus ~ .label {
  transform: translateX(-10px);

  @include media-breakpoint('m') {
    transform: translateY(-10px);
  }
}

.input:focus {
  outline: 3px solid var(--color-blue--dark);
  outline-offset: 2px;
}

.icon {
  position: absolute;
  display: block;
  width: 30px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  @include media-breakpoint('m') {
    top: auto;
    bottom: 0;
  }
}
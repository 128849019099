@import "../../sass/base/_breakpoints.scss";

.title {
  font-size: 3.25rem;
  font-weight: 900;
  color: var(--color-blue--dark);
  word-break: break-word;
  hyphens: auto;
  text-transform: uppercase;

  @include media-breakpoint('m') {
    margin-top: 30px;
    font-size: 3rem;
  }

  @include media-breakpoint('s') {
    font-size: 2.5rem;
    line-height: 2.65rem;
  }
}

.infos {
  border: 3px solid var(--color-pink);
  color: var(--color-blue--dark);
  padding: 20px;
  margin-bottom: 10px;
  font-size: 14px;

  display: flex;
  flex-direction: column;

  position: relative;
}

.expandTrigger {
  width: 15px;
  height: 20px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: var(--color-white);
  //background: green;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
}

.expandTrigger:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-left: 3px solid var( --color-blue--dark );
  border-bottom: 3px solid var( --color-blue--dark );
  transform: translate(-50%, -6px) rotate(-45deg);
}

.is-expanded .expandTrigger:after {
  transform: translate(-50%, 6px) rotate(135deg);
}

.infoTitle {
  font-size: 1.75rem;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--color-pink);
}

.attributeTitle {
  font-weight: bold;
  font-size: 1.25rem;
  margin-top: 20px;
  text-transform: uppercase;
  color: var(--color-pink);
}

.description {
  color: var(--color-blue--dark);
  margin: 30px 0;
  font-size: 22px
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  @include media-breakpoint('m') {
    margin: 25px 0 10px;
  }
}

.navNext,
.navPrev {
  color: var(--color-blue--dark);
  position: relative;
  padding: 10px 0 10px 35px;
  text-decoration: none;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width:  15px;
    height: 15px;
    border-width: 3px 3px 0 0;
    border-style: solid;
    border-color: var(--color-blue--dark);
    transform: rotate(-135deg);
    left: 10px;
    top: 15px;
    transition: margin .3s;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    &:after {
      margin: 0 0 0 -5px;
    }
  }
}

.navNext {
  padding: 10px 35px 10px 0;

  &:after {
    transform: rotate(45deg);
    right: 10px;
    left: auto;
  }

  &:hover {
    &:after {
      margin: 0 -5px 0 0;
    }
  }
}

.buttonHolder {
  padding-bottom: 10px;
  margin-left: -15px;

  button {
    margin: 10px 0;
    width: 100%;
    font-weight: bold;
    padding: 15px;
  }

  a {
    text-decoration: none;
  }
}

.bottomRow {
  border-bottom: 3px solid var(--color-blue--dark);
  align-items: flex-end;
  margin-left: 0;
  margin-top: auto;


  @include media-breakpoint('xl') {
    border: 0;
  }

  @include media-breakpoint('m') {
    display: none;
  }
}

.mobileButtonRow {
  border-bottom: 3px solid var(--color-blue--dark);
  align-items: flex-end;
  margin-left: 0;
  margin-top: 30px;
  display: none;

  @include media-breakpoint('m') {
    display: block;
  }
}

.ambassador {
  display: flex;
  justify-content: flex-end;
  margin-right: -15px;
  align-items: flex-end;

  @include media-breakpoint('xl') {
    flex-direction: column;
    margin-right: 0;
  }
}

.ambassadorText {
  font-style: italic;
  color: var(--color-blue--dark);
  text-align: right;
  padding-right: 20px;
}

.ambassadorImage {
  max-width: 260px;
}

.row {
  @include media-breakpoint('m') {
    flex-direction: column-reverse;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.infoTitleHolder { order: -11; }
.fact[data-title="Kategorie"] { order: -10; }
.fact[data-title="Partner"] { order: -9; }
.fact[data-title="Ort"] { order: -8; }
.fact[data-title="Abschluss"] { order: -7; }
.fact[data-title="Studienart"] { order: -6; }
.fact[data-title="Anwesenheit"] { order: -5; }
.fact[data-title="Sprache"] { order: -4; }
.fact[data-title="Voraussetzungen"] { order: -3; }
.fact[data-title="Berufsfelder"] { order: -2; }
.fact[data-title="Sonstiges"] { order: -1; }

.fact {
  display: none;
}

.fact[data-title="Kategorie"],
.fact[data-title="Partner"],
.fact[data-title="Ort"],
.fact[data-title="Abschluss"] {
  display: block;
}

.infos.is-expanded .fact[data-title="Studienart"],
.infos.is-expanded .fact[data-title="Anwesenheit"],
.infos.is-expanded .fact[data-title="Sprache"],
.infos.is-expanded .fact[data-title="Voraussetzungen"],
.infos.is-expanded .fact[data-title="Berufsfelder"],
.infos.is-expanded .fact[data-title="Sonstiges"] {
  display: block;
}

.programLinks {
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s;

  &.isVisible {
    padding: 0 10px 20px;

    &.hasChildren2 {
      max-height: 160px;
    }
    &.hasChildren3 {
      max-height: 230px;
    }
    &.hasChildren4 {
      max-height: 300px;
    }
    &.hasChildren5 {
      max-height: 370px;
    }
  }
}

.showMoreButton button {
  margin-bottom: 0;
}

.dropdownBtn {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-80%) rotate(45deg);
    width: 20px;
    height: 20px;
    border-right:  4px solid var(--color-white);
    border-bottom: 4px solid var(--color-white);
    transition: transform .3s;
  }

  &.isOpen {
    &:after {
      transform: translateY(-30%) rotate(-135deg);
    }
  }
}
@import "../../sass/base/_breakpoints.scss";

.form {
  opacity: 1;
  transition: opacity .3s;
}

.isLoading {
  opacity: 0.6;
}

.isSuccess {}

.submit {
  display: inline-block;
  font-family: Arkit, sans-serif;
  background: var(--color-red);
  padding: 5px 35px;
  transition: background .3s, color .3s;
  text-transform: uppercase;
  border: 3px solid var(--color-pink);
  color: var(--color-pink);

  &:hover {
    cursor: pointer;
    background: var(--color-pink);
    color: var(--color-white);
  }
}

.hasError {
  > input {
    border-color: var(--color-red);
  }

  + label,
  label {
    color: var(--color-white);
    background: var(--color-red);
  }

  label:before,
  label:after {
    border-color: var(--color-red);
  }
}

.message {
  font-size: 18px;
  text-align: center;
  margin-top: 15px;
  color: var(--color-red);
  transition: font-size .3s;
}

.formTitle {
  font-size: 3rem;
  color: var(--color-blue--dark);
  font-weight: bold;
}

.formText {
  color: var(--color-blue--dark);
  font-weight: bold;
  margin: 30px 0 50px;
}


.messageContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 20px;
}

.messageContainer svg {
  height: 50px;
  margin-left: 20px;
}

.messageContainer svg path {
  fill: var( --color-pink ) !important;
}

.requiredInfo {
  margin-top: 20px;
  color: var(--color-pink);
  font-size: 1rem;
}

.buttonHolder {
  text-align: center;
}

.form {
  transition: opacity .3s;

  &.isLoading {
    opacity: 0.4;
    pointer-events: none;
  }
}
@import "../../../../sass/base/breakpoints";

.buttons {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: -10px;
  margin-right: -10px;
  justify-content: center;

  @include media-breakpoint('m') {
    margin: 0;
    display: block;
  }
}

.buttons.justify {
  justify-content: space-between;

  @include media-breakpoint('m') {
    justify-content: center;
  }
}

.button {
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;

  @include media-breakpoint('m') {
    margin: 0 0 10px;
  }
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.label {
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--color-pink);
  text-transform: uppercase;
  cursor: pointer;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;

  border: 3px solid var(--color-pink);
  display: inline-block;
  padding: 5px 25px;
  background-color: transparent;

  @include media-breakpoint('m') {
    padding: 5px 15px;
    display: block;
    text-align: center;
  }

  @include media-breakpoint('s') {
    font-size: 1.25rem;
  }
}

.input:checked ~ .label {
  background-color: var(--color-pink);
  color: var(--color-white);
}

.input:active ~ .label {
  transform: scale(0.95) translateY(3px);
}

.input:focus ~ .label {
  outline: 3px solid var(--color-blue--dark);
  outline-offset: 2px;
}
@import "../../../sass/base/_breakpoints.scss";

.comingSoonContainer {
  background-color: var( --color-pink );
  color: var( --color-white );
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2.3125rem;
  max-width: 900px;

  @include media-breakpoint('xxl') {
    font-size: 1.75rem;
  }

  @include media-breakpoint('m') {
    font-size: 1.1875rem;
  }
}

.text p:first-child,
.text h1:first-child {
  @include media-breakpoint('m') {
    max-width: 65%;
  }
}

.text h1 {
  font-size: 6rem;

  @include media-breakpoint('xxl') {
    font-size: 4rem;
  }

  @include media-breakpoint('m') {
    font-size: 2.5rem;
    line-height: 1;
  }
}

.text strong {
  color: var( --color-blue--dark );
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  @include media-breakpoint('m') {
    padding: 20px 0;
  }
}

.imageLeft {
  width: 212px;

  @include media-breakpoint('m') {
    width: 120px;
  }
}

.imageRight {
  width: 525px;
  margin-bottom: -120px;

  @include media-breakpoint('xl') {
    width: 212px;
  }

  @include media-breakpoint('m') {
    margin-bottom: -55px;
    width: 120px;
    margin-top: 100px;
  }
}

.imageRight svg path,
.imageLeft svg path,
.imageLeft svg polygon,
.footerLeft svg path {
  fill: #fff !important;
}

.footer {
  padding: 30px 0;
  margin-top: auto;

  @include media-breakpoint('m') {
    padding: 115px 0 20px 0;
  }
}

.footer p {
  margin: 0;
}

.footerLeft {
  display: flex;
  align-items: flex-end;

  @include media-breakpoint('m') {
    margin-bottom: 20px;
    justify-content: flex-end;
  }
}

.socialIcons {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.hashtag {
  font-size: 1.5rem;
  line-height: 1;
  margin-left: 25px;
}

.footerRight {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint('s') {
    flex-direction: column;
    margin-bottom: -20px;
    text-align: right;
  }
}

.footerRight a {
  color: var( --color-white );
  text-decoration: none;
  display: block;
}

.footerRight address {
  font-style: normal;
  margin: 0;

  @include media-breakpoint('s') {
    margin-bottom: 20px;
  }
}

@import "../../../../sass/base/breakpoints";

.title {
  font-weight: 900;
  text-align: center;
  font-size: 1.625rem;
  text-transform: uppercase;

  @include media-breakpoint('m') {
    font-size: 1.5rem;
  }
}

.title strong {
  color: var(--color-pink);
}

.answers {
  margin-top: 40px;
}

.nextButton {
  background-color: var(--color-blue--dark);
  color: var(--color-white);
  border: 3px solid var(--color-blue--dark);
  padding: 5px 50px;
  font-weight: 900;
  font-size: 1.5rem;
  display: block;
  margin: 40px auto 0;
  cursor: pointer;

  @include media-breakpoint('m') {
    margin: 40px auto;
  }
}

.nextButton:focus {
  outline: 3px solid var(--color-pink);
  outline-offset: 2px;
}

.nextButton.disabled {
  opacity: .5;
  cursor: not-allowed;
}

//.blur {
//  position: relative;
//  filter: blur(0px);
//  transition: all 1s;
//}
//
//.blur.active {
//  filter: blur(3px);
//}

//.shimmer {
//  animation-duration: 2.2s;
//  animation-fill-mode: forwards;
//  animation-iteration-count: infinite;
//  animation-name: shimmer;
//  animation-timing-function: linear;
//  background: #ddd;
//  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
//  background-size: 1200px 100%;
//}

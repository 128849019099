@import "_wysiwyg.css";

.font-color--red { color: var( --color-red ); }
.font-color--blue { color: var( --color-blue ); }
.font-color--blue-dark { color: var( --color-blue--dark ); }
.font-color--black { color: var( --color-black ); }
.font-color--pink { color: var( --color-pink ); }

.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  border: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.hidden:focus {
  clip: auto;
  height: auto;
  overflow: auto;
  position: absolute;
  width: auto;
}

.o-embed {
  position: relative;
  padding-top: 56.25%;
}

.o-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

iframe {
  width: 100%;
}

p a {
  text-decoration: none;
  color: inherit;
  background-image: linear-gradient(to right, var(--color-blue--dark), var(--color-blue--dark));
  background-repeat: no-repeat;
  background-position: 0 93%;
  background-size: 100% 1px;
  transition: all .3s;
}

p a:visited {
  color: inherit;
}

p a:hover {
  background-size: 0 1px;
}

.font-color--red p a { background-image: linear-gradient(to right, var(--color-red), var(--color-red)); }
.font-color--blue p a { background-image: linear-gradient(to right, var(--color-blue), var(--color-blue)); }
.font-color--blue-dark p a { background-image: linear-gradient(to right, var(--color-blue--dark), var(--color-blue--dark)); }
.font-color--black p a { background-image: linear-gradient(to right, var(--color-black), var(--color-black)); }
.font-color--pink p a { background-image: linear-gradient(to right, var(--color-pink), var(--color-pink)); }
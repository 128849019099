.row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.align--middle { align-items: center; }
.row.align--top { align-items: flex-start; }
.row.align--bottom { align-items: flex-end; }

.row.justify--between { justify-content: space-between; }
.row.justify--start { justify-content: flex-start; }
.row.justify--end { justify-content: flex-end; }
.row.justify--center { justify-content: center; }
.row.justify--around { justify-content: space-around; }
.row.justify--evenly { justify-content: space-evenly; }

.row.spacing--small {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row.spacing--small > div {
  padding: 0 7.5px;
}

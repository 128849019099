@import "../../sass/base/breakpoints.scss";

.wrapper {
  width: 450px !important;
  max-width: 100%;
  top: 50%;
  left: 50% !important;
  bottom: auto !important;
  transform: translate(-50%, -50%);
}

:root {
  /* COLORS */
  --cookie-consent-banner-colors-primary: var(--color-pink);
  --cookie-consent-banner-colors-primary-border: var(--color-pink);
  --cookie-consent-banner-colors-primary-content: #fff;
  --cookie-consent-banner-colors-secondary: transparent;
  --cookie-consent-banner-colors-secondary-border: #fff;
  --cookie-consent-banner-colors-secondary-content: #fff;
  --cookie-consent-banner-colors-background-body: var(--color-blue--dark);
  --cookie-consent-banner-colors-text: #fff;

  /* BORDER-RADIUS */
  --cookie-consent-banner-border-radius-buttons: 0;
  --cookie-consent-banner-border-radius-body: 0;

  /* SPACINGS */
  --cookie-consent-banner-spacings-container-padding-top:    30px;
  --cookie-consent-banner-spacings-container-padding-left:   25%;
  --cookie-consent-banner-spacings-container-padding-bottom: 30px;
  --cookie-consent-banner-spacings-container-padding-right:  25%;

  --cookie-consent-banner-spacings-body-padding-top: 0;
  --cookie-consent-banner-spacings-body-padding-left: 2rem;
  --cookie-consent-banner-spacings-body-padding-bottom: 0;
  --cookie-consent-banner-spacings-body-padding-right: 2rem;

  /* Z-INDEX */
  --cookie-consent-banner-z-index-container: 99;

  /* FONTS */
  --cookie-consent-banner-font-family-headline: inherit;
  --cookie-consent-banner-font-size-headline: 1.5rem;
  --cookie-consent-banner-font-family-body: inherit;
  --cookie-consent-banner-font-size-body: 0.875rem;



  @include media-breakpoint('l') {
    /* SPACINGS */
    --cookie-consent-banner-spacings-container-padding-top:    30px;
    --cookie-consent-banner-spacings-container-padding-left:   10%;
    --cookie-consent-banner-spacings-container-padding-bottom: 30px;
    --cookie-consent-banner-spacings-container-padding-right:  10%;
  }
}



.banner {
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    content: '';
    z-index: 98;
    opacity: 0;
    transition: opacity .3s;
    transition-delay: .5s;
    pointer-events: none;
  }

  &.isVisible:after {
    opacity: 1;
    pointer-events: auto;
  }
}

.checkboxContainer {
  margin-bottom: 20px;
}

.checkboxContainer p {
  margin: 0;
}

.label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  line-height: 20px;
  font-weight: 500;
}

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-white);
}

.label input:checked ~ .check {
  background: var(--color-white);
}

input:focus ~ .check {
  box-shadow: var(--site-color-shadow);
}

.check:after {
  content: '';
  position: absolute;
  display: none;
}

.label input:checked ~ .check:after {
  display: block;
}

.label .check:after {
  left: 4px;
  top: -3px;
  width: 8px;
  height: 18px;
  border: solid var(--color-pink);
  border-width: 0 3px 3px 0;
  transform: rotate(37deg);
}

.label a {
  color: var(--color-white);
}



.error {
  font-size: 1.125rem;
  font-style: italic;
  margin-top: 5px;
}

.relatedContainer {
    margin-top: 30px;
    width: 100%;
}

.relatedSlider {
    margin-top: 20px;
}

.relatedContainer .swiper-wrapper {
    box-sizing: border-box;
}

.relatedContainer h2 {
    font-weight: 900;
    text-transform: uppercase;
}

.related__slide {
    padding: 20px;
    border: 3px solid var(--color-blue--dark);
    height: auto;
}

.related__inner {
    display: flex;
    align-items: center;
    height: 100%;
}

.related__title {
    flex-grow: 1;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.25rem;
}

.related__button {
    flex-grow: 0;
    flex-shrink: 1;
    white-space: nowrap;
}

.related__outer {
    position: relative;
}

.related__prev,
.related__next {
    cursor: pointer;
    width: 40px;
    height: 40px;

    position: absolute;
    top: 50%;
    z-index: 1;
}

.related__prev {
    left: -40px;
    transform: translateY( -50% );
}

.related__next {
    right: -40px;
    transform: translateY( -50% );
}

.related__prev:after,
.related__next:after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    border-left: 3px solid var(--color-blue--dark);
    border-bottom: 3px solid var(--color-blue--dark);
    position: relative;
}

.related__prev:after {
    transform: rotate(45deg);
    top: 5px;
    left: 10px;
}

.related__next:after {
    transform: rotate(-135deg);
    top: 5px;
}

.related__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.related__header-controls {
    display: flex;
    align-items: center;
}

.related__header .related__prev,
.related__header .related__next {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: translateY(0);
}

.related__header .related__header-controls {
    display: none;
}

@media only screen and (max-width: 1350px) {
    .related__header .related__header-controls {
        display: flex;
    }

    .related__outer .related__prev,
    .related__outer .related__next {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .related__inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .related__button {
        margin: 10px auto 0 0;
    }
}
:root {
  /*--------------------------------------------------------------
  # TYPOGRAPHY
  --------------------------------------------------------------*/

  --site-font-family: 'Montserrat', sans-serif;
  --site-font-size: 1.5rem;
  --site-font-size-mobile: 1rem;
  --site-line-height: 1.35;
  --site-font-weight: 400;
  --site-letter-spacing: 0;
  --paragraph-offset: 1.5em;
  --secondary-font-family: serif;

  /*--------------------------------------------------------------
  # COLORS
  --------------------------------------------------------------*/

  --color-white: #ffffff;
  --color-black: #000000;
  --color-pink: #d46f91;
  --color-blue--dark: rgb( 51, 52, 116 );
  --color-blue: rgb(0, 95, 204);
  --color-red: #992929;

  --site-color-bg: #ffffff;
  --site-color-text: rgb( 51, 52, 116 );
  --site-color-shadow: 0 0 10px #0000004f;
}

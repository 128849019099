@import "../../../../sass/base/breakpoints";

.selects {
  text-align: center;
  position: relative;
}

.selects:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  border-bottom: 3px solid var(--color-pink);
  border-right: 3px solid var(--color-pink);
  transform: translateY(-50%) rotate(45deg);
  pointer-events: none;

  position: absolute;
  top: 40%;
  right: 20px;
}

.select {
  width: 100%;
  border: 3px solid var(--color-pink);
  padding: 10px 55px 10px 10px;
  font-weight: 900;
  color: var(--color-pink);
  text-transform: uppercase;
  font-size: 1.5rem;
  appearance: none;
  border-radius: 0;
  -webkit-appearance: none;
  background: transparent;

  @include media-breakpoint('m') {
    font-size: 1rem;
  }
}

.select:focus {
  outline-offset: 2px;
  outline: 3px solid var(--color-blue--dark);
}
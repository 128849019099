@import "../../sass/base/_breakpoints.scss";

.image {
  width: 100%;
}

.slide {
  position: relative;
}

.swiper {
  width: 100%;
}

.next,
.prev {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width:  80px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prevIcon,
.nextIcon {
  content: '';
  display: block;
  width:  25px;
  height: 25px;
  border-width: 3px 3px 0 0;
  border-color: white;
  border-style: solid;
  transform: rotate(45deg);
  position: relative;
  right: 0;
}

.prevIcon {
  transform: rotate(-135deg);
  left: 0;
}

@import "../../sass/base/_breakpoints.scss";

.footer {
  color: var(--color-blue--dark);
  padding: 125px 0 30px;
  margin-top: auto;

  @include media-breakpoint('m') {
    padding: 115px 0 20px 0;
  }
}

.bottom {
  margin-top: 70px;
}

.footer p {
  margin: 0;
}

.socialColumn {
  display: flex;
  align-items: flex-end;

  @include media-breakpoint( 'xl' ) {
    flex-wrap: wrap;
  }

  @include media-breakpoint('m') {
    margin-bottom: 20px;
    justify-content: flex-end;
  }
}

.addressColumn {
  font-weight: 500;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint('s') {
    flex-direction: column;
    margin-bottom: -20px;
    text-align: right;
  }
}

.addressColumn a {
  color: var( --color-blue--dark );
  text-decoration: none;
  display: block;
}

.addressColumn address {
  font-style: normal;
  margin: 0;

  @include media-breakpoint('s') {
    margin-bottom: 20px;
  }
}

.addressColumn address:last-child {
  margin-top: auto;

  //@include media-breakpoint('')
}

.socialIcons {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  flex-shrink: 0;
}

.hashtag {
  font-size: 1.5rem;
  line-height: 1;
  margin-left: 25px;

  @include media-breakpoint( 'xl' ) {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }

  @include media-breakpoint( 'm' ) {
    width: auto;
    margin-left: 25px;
    margin-top: 0;
  }
}

.menuColumn {
  @include media-breakpoint('s') {
    text-align: right;
    margin-top: 20px;
  }
}

.navUl {
  list-style: none;
  margin: 0;
}

.menuItem {
  color: var(--color-blue--dark);
  text-decoration: none;
  font-weight: 500;
}

.support {
  font-size: 14px;
  margin-top: 40px;
  display: block;
  text-decoration: none;
  color: var(--color-blue--dark);
}

.bka {
  max-width: 200px;
  margin-top: 20px;
  display: block;
}

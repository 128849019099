@import "../../sass/base/_breakpoints.scss";

.image {
  width: 100%;
}

.video {
  width: 100%;
}

.slider {
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
  }
}

.textHolder {
  white-space: pre-wrap;

  ul {
    white-space: initial;
    list-style: none;
  }

  ul > li {
    position: relative;
    padding-left: 1.35em;
    margin-top: 5px;
  }

  ul > li:before {
    content: '';
    font-size: 1.5em;
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  @include media-breakpoint('m') {
    margin: 30px 0;
  }
}

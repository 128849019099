@import '../../../../sass/base/breakpoints.scss';

.filter {

}

.table {
  border-collapse: collapse;
  color: var(--color-blue--dark);

  @include media-breakpoint('m') {
    display: block;
  }
}

.tableData {
  border-bottom: 1px solid var(--color-pink);
  border-right: 1px solid var(--color-pink);
  padding: 25px 15px;

  @include media-breakpoint('m') {
    display: block;
    padding: 15px;
    transition: background .3s, color .3s;
  }
}

.thead {
  border: 3px solid var(--color-pink);
}

.thead,
.tbody, tr,
.tbody {
  @include media-breakpoint('m') {
    display: block;
    width: 100%;
  }
}

.tbody .tableRow:first-child {
  border-top: 3px solid var(--color-pink);
}

.tbody .tableRow:last-child {
  border-bottom: 3px solid var(--color-pink);
}

.tbody .tableRow {
  border-left: 3px solid var(--color-pink);
  border-right: 3px solid var(--color-pink);
}

.tableRow td:last-child {
  width: 100%;
  padding: 25px 20px;
  font-weight: 500;

  @include media-breakpoint('m') {
    height: 0;
    padding: 0;
    border: 0;
  }
}

.tableRow td:first-child {
  font-weight: 900;
  text-transform: uppercase;
}

.tableRow td:first-child {
  &:active {
    @include media-breakpoint('m') {
      background: var(--color-blue--dark);
      color: var(--color-white);
    }
  }
}

.attributeTitle {
  text-align: right;

  @include media-breakpoint('m') {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.terms {
  margin-bottom: -10px;
  margin-right: -10px;

  @include media-breakpoint('m') {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-white);
    margin: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateY(100%);
    transition: transform .3s, visibility .3s;
    display: flex;
    flex-direction: column;
    z-index: 999;

    &.isVisible {
      pointer-events: auto;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.termCount {
  display: none;

  @include media-breakpoint('m') {
    display: block;
    background: var(--color-blue--dark);
    color: var(--color-white);
    padding: 3px 5px;
    min-width: 25px;
    text-align: center;
    margin-left: 15px;
  }
}

.termTitle {
  display: none;

  @include media-breakpoint('m') {
    display: flex;
    justify-content: space-between;
    background: var(--color-blue--dark);
    color: var(--color-white);
    padding: 20px 15px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.termClose {
  position: relative;
  width: 40px;

  &:before,
  &:after {
    background: var(--color-white);
    content: "";
    display: block;
    height: 2px;
    right: 0;
    position: absolute;
    top: 10px;
    width: 26px;
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.termsHolder {
  @include media-breakpoint('m') {
    flex: 1;
    overflow: auto;
    padding-bottom: 45px;
  }
}

.termSubmit {
  display: none;

  @include media-breakpoint('m') {
    display: block;
    padding: 15px;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      top: -45px;
      background: linear-gradient( to top, var(--color-white), transparent );
      content: '';
      display: block;
      width: 100%;
      height: 45px;
    }
  }
}

.tbody:before {
  content: '@';
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.thead .tableData:first-child {
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 15px;

  @include media-breakpoint('m') {
    padding: 15px;
  }
}

.thead .tableData:last-child {
  background: var(--color-pink);
  padding: 0;
}

.search {
  width: 100%;
  height: 50px;
  display: block;
  background: transparent;
  border: 0;
  padding: 0 40px 0 20px;
  color: var(--color-white);
  outline: 0;
  font-weight: 600;
}

.search::placeholder {
  color: var(--color-white);
}

.searchContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  display: block;
  width: 30px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.reset {
  //background: var( --color-blue--dark ) !important;
  //border-color: var( --color-blue--dark ) !important;
}
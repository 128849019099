.checkboxContainer {
  margin-top: 40px;
}

.checkboxContainer p {
  margin: 0;
}

.label {
  color: var(--color-pink);
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 1rem;
}

.checkbox {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border: 2px solid var(--color-pink);
}

.label input:checked ~ .check {
  background: var(--color-white);
}

.check:after {
  content: '';
  position: absolute;
  display: none;
}

.label input:checked ~ .check:after {
  display: block;
}

.label .check:after {
  left: 7px;
  top: 1px;
  width: 7px;
  height: 16px;
  border: solid var(--color-pink);
  border-width: 0 3px 3px 0;
  transform: rotate(37deg);
}

.label a {
  color: var(--color-pink);
}


.error {
  font-size: 1rem;
  font-style: italic;
  margin-top: 5px;
}

@import "../../../../sass/base/breakpoints.scss";

.item {
  border-bottom: 1px solid var(--color-blue--dark);
  padding: 15px 20px;
  display: flex;
  align-items: center;

  @include media-breakpoint('m') {
    flex-wrap: wrap;
    padding: 20px;
  }
}

.item:last-child {
  border-bottom: 0;
}

.title {
  font-size: 1.25rem;
  font-weight: 900;
  text-transform: uppercase;
  flex: 0 0 auto;
  width: 38%;
  max-width: 38%;
  margin-right: 10px;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;

  @include media-breakpoint('m') {
    font-size: 1.1rem;
    width: 100%;
    flex-basis: 100%;
    max-width: none;
  }
}

.university {
  width: 18%;
  margin-right: 10px;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.location {
  width: 15%;
  margin-right: 10px;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.degree {
  width: 9%;
  margin-right: 10px;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.university > div,
.location > div,
.degree > div {
  margin-top: 5px;

  &:first-child {
    margin: 0;
  }
}

.buttonContainer {
  width: 16%;
  margin-left: auto;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.university.multiple,
.location.multiple {
  @include media-breakpoint('m') {
    padding-bottom: 5px;
    margin-bottom: 5px;
    background-image: linear-gradient(to right, var(--color-blue--dark), var(--color-blue--dark));
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 50% 1px;
  }
}
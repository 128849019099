body,
button,
input,
select,
optgroup,
textarea {
  font-size: var(--site-font-size);
  font-family: var(--site-font-family);
  font-weight: var(--site-font-weight);
  line-height: var(--site-line-height);
  letter-spacing: var(--site-letter-spacing);
  color: var(--site-color-text);
}

@media only screen and (max-width: 767px) {
  body,
  button,
  input,
  select,
  optgroup,
  textarea {
    font-size: var(--site-font-size-mobile);
  }
}

@import "_headings.css";
@import "_copy.css";
@import "_fonts.css";

.button {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: var(--color-white);
  background: var(--color-pink);
  border: 3px solid var(--color-pink);
  padding: 10px 20px;
  display: block;
  cursor: pointer;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  font-weight: 900;

  &.hasIcon {
    padding-right: 45px;
    position: relative;
  }
}

.button--center {
  margin: 0 auto;
}

.button--left {
  margin-right: auto;
}

.button--right {
  margin-left: auto;
}

.button:focus {
  outline: 3px solid var(--color-blue--dark);
  outline-offset: 2px;
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  width:  36px;
  height: 36px;
  transform: translateY(-50%);
}


.mainContent {
  flex: 1 0 auto;
  overflow: hidden;
}

.page404 {
  padding: 100px 0;
}

.title404 {
  font-size: 102px;
  color: var(--color-red);
}

.text404 {
  font-size: 22px;
}
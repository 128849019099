.button {
  background: transparent;
  color: var(--color-pink);
  border: 3px solid var(--color-pink);
  cursor: pointer;
  outline: none;
  padding: 5px 20px;
  font-weight: 500;
  transition: all .25s;
  margin-top: 50px;
}

.button:hover {
  background-color: var(--color-pink);
  color: var(--color-white);
}

.button:focus {
  box-shadow: var(--site-color-shadow);
}

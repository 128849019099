@import "../../sass/base/breakpoints";

.navigationContainer {
  background: var(--color-pink);
  position: fixed;
  width: 0;
  height: 20px;
  right: 0;
  top: 0;
  min-width: 0;
  transition: height .3s, width .3s .3s, min-width .3s .3s;
  overflow: auto;
  z-index: 10;
}

.navigationContainer.isOpen {
  width: calc( 100% / 2 - 1300px / 2 + 123px );
  min-width: 500px;
  height: 100%;

  transition: width .3s, min-width .3s, height .3s .3s;

  @include media-breakpoint( 'm' ) {
    min-width: 100%;
  }
}

.navigation {
  padding: 140px 20px 140px 40px;

  @include media-breakpoint('s') {
    padding: 140px 20px 140px 40px;
  }

  @include media-breakpoint('xxs') {
    padding: 140px 20px;
  }
}

.navigationList {
  list-style: none;
  margin: -20px 0;
  padding: 0;
}

.navigationItem {
  font-size: 1.5625rem;
  color: var(--color-white);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 20px 0;

  background: linear-gradient( to right, var(--color-white), var(--color-white) );
  background-position: 0 76%;
  background-repeat: no-repeat;
  background-size: 0 3px;

  transition: background .3s ease;

  @include media-breakpoint('s') {
    font-size: 1.25rem;
  }

  @include media-breakpoint('xxs') {
    font-size: 1.125rem;
  }
}

.navigationItem:hover {
  background-size: 100% 3px;
}

.navigationItem.active {
  font-weight: 900;
}

@import "../../../../sass/base/breakpoints.scss";

.container {
  margin-top: 150px;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 30px;

  @include media-breakpoint('m') {
    display: none;
  }
}

.pageLink {
  border: 1px solid var(--color-blue--dark);
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
}

.pageLink.active {
  background: var(--color-blue--dark);
  color: var(--color-white);
}

.pageLink:last-child {
  margin-right: 35px;
}

.nextButton,
.prevButton {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 2px solid var(--color-blue--dark);
  border-bottom: 2px solid var(--color-blue--dark);
  transform: translateY(5px) rotate(-45deg);
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.prevButton {
  transform: translateY(5px) rotate(135deg);
}

.results {
  border: 3px solid var(--color-blue--dark);
  position: relative;
  color: var(--color-blue--dark);
}

.banner {
  width: 450px;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.resultsTitle {
  font-size: 1.9rem;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 450px;
  line-height: 1;

  position: absolute;
  left: -20px;
  top: 0;
  transform: translateY(-70%);

  @include media-breakpoint('m') {
    font-size: 1.25rem;
    left: -15px;
  }
}

.resultsTitleText {
  position: absolute;
  color: var(--color-white);
  top: 30%;
  left: 25px;
}

.inner {}

.navigation.navigationBottom {
  margin-top: 30px;
  margin-bottom: 0;

  @include media-breakpoint('m') {
    display: flex;
  }
}

.noResults {
  padding: 50px 15px;
}
@import "../../sass/base/breakpoints.scss";

.inner {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 0;
}

.logo {
  width: 212px;

  @include media-breakpoint('m') {
    width: 50%;
    margin-right: 30px;
  }
}

.circle {
  width: 250px;
  margin-left: auto;
  margin-right: 60px;
  margin-top: 20px;
  max-height: 280px;

  @include media-breakpoint('m') {
    margin: 0 30px 0 0;
    width: 50%;
  }
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.backButton {
  font-size: 0.88rem !important;
  padding: 5px !important;
}
@import "../../../sass/base/_breakpoints.scss";

.inputContainer {
  margin-top: 15px;
  margin-right: 20px;
  width: 100%;
  display: flex;

  @include media-breakpoint('m') {
    width: 100%;
    margin-right: 0;
    display: block;
  }
}

.input {
  padding: 10px;
  background-color: transparent;
  outline: none;
  border: 3px solid var(--color-pink);
  color: var( --color-pink );
  font-weight: 500;
  flex-basis: 80%;
  width: 80%;
  min-height: 200px;
  border-radius: 0;
  -webkit-appearance: none;

  @include media-breakpoint('m') {
    width: 100%;
  }
}

.input:focus {
  outline: 3px solid var(--color-blue--dark);
  outline-offset: 2px;
}

.input::placeholder {
  color: var(--color-white);
}

.error {
  font-size: 1rem;
  font-style: italic;
  text-align: right;
  margin: 5px 0 15px;
}

.label {
  flex-basis: 20%;
  width: 20%;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-pink);
  padding-top: 10px;

  @include media-breakpoint('m') {
    width: 100%;
  }
}
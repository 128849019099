.text {
  white-space: pre-line;

  ul,
  ol {
    padding-left: 30px;
    line-height: 19px;
  }
}

.text a {
  text-decoration: none;
  color: inherit;
  background-image: linear-gradient(to right, var(--color-blue--dark), var(--color-blue--dark));
  background-repeat: no-repeat;
  background-position: 0 93%;
  background-size: 100% 1px;
  transition: all .3s;
}

.text a:visited {
  color: inherit;
}

.text a:hover {
  background-size: 0 1px;
}

.font-color--red .text a { background-image: linear-gradient(to right, var(--color-red), var(--color-red)); }
.font-color--blue .text a { background-image: linear-gradient(to right, var(--color-blue), var(--color-blue)); }
.font-color--blue-dark .text a { background-image: linear-gradient(to right, var(--color-blue--dark), var(--color-blue--dark)); }
.font-color--black .text a { background-image: linear-gradient(to right, var(--color-black), var(--color-black)); }
.font-color--pink .text a { background-image: linear-gradient(to right, var(--color-pink), var(--color-pink)); }

.cc {
  background: pink !important;
}

#mc_embed_signup {
  white-space: normal;
  max-width: 1020px;
}

#mc_embed_signup input {
  padding: 10px;
  background-color: transparent;
  outline: none;
  border: 3px solid var(--color-pink);
  color: var( --color-pink );
  font-weight: 500;
  flex-basis: 80%;
  width: 80%;
  border-radius: 0;
  -webkit-appearance: none;
}

#mc_embed_signup input.button {
  flex-basis: auto;
  width: auto;
  transition: background .3s, color .3s;
}

#mc_embed_signup input.button:hover {
  background:var(--color-pink);
  color: var( --color-white );
  cursor: pointer;
}

#mc_embed_signup label {
  flex-basis: 20%;
  width: 20%;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-pink);
  text-align: left;
  word-break: break-word;
  hyphens: auto;
}

#mc_embed_signup .mc-field-group {
  margin-top: 15px;
  margin-right: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 680px) {
  #mc_embed_signup input {
    width: 100%;
  }

  #mc_embed_signup label {
    width: 100%;
    display: block;
  }

  #mc_embed_signup .mc-field-group {
    width: 100%;
    margin-right: 0;
    display: block;
  }
}
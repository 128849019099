.loadingIndicator {
  position: relative;
  height: 100%;
}

.logo {
  max-width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  position: relative;
  background: var(--site-color-bg);
  overflow: scroll;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

hr {
  background-color: #cccccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}

ul,
ol {
  margin: 0 0 1.5em;
  padding-left: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

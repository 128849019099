@import "../../../sass/base/_breakpoints.scss";

.signupForm {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 700px;
}

.signupForm input,
.signupForm button {
  min-width: 215px;
}

.signupForm button {
  margin-top: 30px;
  @include media-breakpoint('m') {
    width: 100%;
    margin-bottom: 20px;
  }
}

.messageContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 20px;
}

.messageContainer svg {
  height: 50px;
  margin-left: 20px;
}

.messageContainer svg path {
  fill: var( --color-white ) !important;
}

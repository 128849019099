@import "../../../sass/base/_breakpoints.scss";

.inputContainer {
  margin-bottom: 20px;
  margin-right: 20px;
  width: 100%;

  @include media-breakpoint('m') {
    width: 100%;
    margin-right: 0;
  }
}

.input {
  padding: 5px 20px;
  background-color: transparent;
  outline: none;
  border: 3px solid var(--color-white);
  color: var( --color-white );
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.input:focus {
  box-shadow: var(--site-color-shadow);
}

.input::placeholder {
  color: var(--color-white);
}

.error {
  font-size: 1.125rem;
  font-style: italic;
  margin-top: 5px;
}

.button {
  background-color: transparent;
  background-image: linear-gradient(to right, var(--color-white), var(--color-white));
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  color: var(--color-blue--dark);

  outline: none;
  border: 3px solid var(--color-white);
  padding: 5px 20px;
  font-weight: 500;

  transition: all .25s;
}

.button:hover {
  background-size: 100% 0;
}

.button:focus {
  box-shadow: var(--site-color-shadow);
}

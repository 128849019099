/**
 Typical font-weight names/aliases

 100 => Thin, Hairline
 200 => Extra Light, Ultra Light
 300 => Light
 400 => Normal, Regular
 500 => Medium
 600 => Semi Bold, Demi Bold
 700 => Bold
 800 => Extra Bold, Ultra Bold
 900 => Black, Heavy
 */

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Montserrat-Regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Montserrat-Regular.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/Montserrat-Regular.svg') format('svg'); /* Legacy iOS */
}

/* montserrat-medium - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Montserrat-Medium.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/Montserrat-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Montserrat-Medium.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/Montserrat-Medium.svg') format('svg'); /* Legacy iOS */
}

/* montserrat-bold - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Montserrat-Bold.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Montserrat-Bold.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/Montserrat-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/Montserrat-Bold.svg') format('svg'); /* Legacy iOS */
}

/* montserrat-bold - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/Montserrat-BoldItalic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/Montserrat-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Montserrat-BoldItalic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/Montserrat-BoldItalic.svg') format('svg'); /* Legacy iOS */
}

/* montserrat-black - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/Montserrat-Black.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/Montserrat-Black.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Montserrat-Black.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/Montserrat-Black.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/Montserrat-Black.svg') format('svg'); /* Legacy iOS */
}

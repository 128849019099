@import "./../../sass/base/breakpoints";

.partners .swiper-wrapper {
    align-items: center;
}

.partners .swiper-slide {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partners .swiper-slide img {
    width: auto;
    position: relative;
    display: block;

    //filter: invert(19%) sepia(44%) saturate(1257%) hue-rotate(207deg) brightness(98%) contrast(94%);

}

.partners__text {
    font-size: 10px;
    position: absolute;
    color: var(--color-blue--dark);
    text-decoration: none;
    white-space: nowrap;
}

.partners__container {
    display: flex;
}

.partners__main {
    margin-right: 40px;
    position: relative;
    flex-shrink: 0;

    @include media-breakpoint( 'm' ) {
        margin-right: 20px;
    }

    @include media-breakpoint( 'xs' ) {
        margin-right: 40px;
    }
}
@import "../../../sass/base/breakpoints";

.configurator {
  padding: 105px 90px 80px;
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  transition: opacity .3s;

  @include media-breakpoint('m') {
    padding: 75px 35px;
  }
}

.configurator.finished {
  opacity: 0.7;
}

.corner {
  max-width: 60px;
  position: absolute;
}

.cornerLT {
  top: 25px;
  left: 0;
}

.cornerLB {
  bottom: 0;
  left: 0;
  max-width: 80px;
}

.cornerRT {
  top: 25px;
  right: 0;
}

.cornerRB {
  bottom: 0;
  right: 0;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: underline;
  cursor: pointer;
}